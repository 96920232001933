.header {
	margin: 0 auto;
	padding: 10px calc(50% - 1540px / 2);
	position: relative;
	z-index: 6;
	background: #1c1f1e;
	box-sizing: border-box;
	width: 100%;
	height: 68px;
	display: flex;
	justify-content: center;

	&:hover {
		z-index: 300;
	}
}

.logoLink {
	height: clamp(40px, 3.125vw, 39px);
}

.logo {
	width: clamp(130px, 10.2vw, 126px);
	height: clamp(40px, 3.125vw, 39px);
	color: #fff;
}

.controls {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	width: 100%;
}

.menu {
	flex: 1 0 auto;

	ul {
		padding: 0;
		list-style: none;
	}

	>ul {
		margin: 0 auto 0 20px;
		display: flex;
		flex-direction: row;
		gap: clamp(48px, 3.125vw, 60px);
	}
}

.subMenuWrapper {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	display: grid;
	overflow: hidden;
	opacity: 0;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.5s ease-in-out, opacity 0.8s ease-in-out;
	box-sizing: border-box;

	.subMenu {
		width: 100%;
		padding: 0px calc(50% - 1540px / 2);
		background: #1c1f1e;
		overflow: hidden;
		transition: padding 0.5s ease-in-out;
		box-sizing: border-box;
	}
}

.open {
	.menuBtn {
		svg {
			transform: rotate(180deg);
		}
	}

	.subMenuWrapper {
		transition: grid-template-rows 0.5s ease-in-out, opacity 0.5s ease-in-out;
		opacity: 1;
		grid-template-rows: 1fr;

		.subMenu {
			padding: 30px calc(50% - 1540px / 2);
		}
	}
}

.subMenu {
	display: flex;
	justify-content: flex-start;
	gap: 45px;
	color: #fff;

	ul {
		padding: 6.5px 0 4px;
	}

	ul:first-child {
		padding-right: 45px;
		border-right: 1px solid #717187;
	}
}

.subMenuTitle {
	display: flex;
	justify-content: flex-start;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	gap: 12px;
	margin: 0 0 20px;
}

.subMenuItem {
	color: #fff;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
}

.menuBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	color: #ffffff;
	border: none;
	background-color: transparent;
	text-decoration: none;
	padding: 0;
}

.authLink {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-style: normal;
	font-weight: 500;
	font-size: clamp(14px, 0.83vw, 16px);
	line-height: 120%;
	color: #fff;
	border: 1.5px solid #b472dd;
	background: #b472dd;
	border-radius: 12px;
	padding: 0 clamp(8px, 1.67vw, 32px);
	min-height: clamp(40px, 2.92vw, 44px);
	box-sizing: border-box;
	position: relative;
	border-radius: 100px;
	transition: all 0.3s;
	text-align: center;

	&:hover {
		background-color: transparent;
		color: #b472dd;
	}
}

.imageWrapper {
	position: relative;
	max-height: 320px;
	background: var(--primary);
	border-radius: 16px;
	overflow: hidden;

	.original,
	.result {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 10px;
		border-radius: 100px;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		font-size: 14px;
		padding: 6px 12px;
	}

	.original {
		left: 16px;
	}

	.result {
		right: 16px;
	}

	.prompt {
		border-radius: 100px;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		position: absolute;
		bottom: 10px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 11;
		white-space: nowrap;
		padding: 6px 12px;
		font-size: clamp(14px, 1.56vw, 16px);

		span {
			color: #717187;
		}
	}
}

.item_img {
	width: 100%;
	max-height: 320px;
	object-fit: cover;
	object-position: center;
}

.profileLink {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #f9f8fe;
	width: 48px;
	height: 48px;
	background: #6371de;
	border-radius: 40px;
	text-decoration: none;

	img {
		max-width: 100%;
		max-height: 100%;
	}

	&:hover {
		opacity: 0.8;
	}

	overflow: hidden;
}

.content {
	padding: 0;
	overflow: hidden;
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.3s ease-in-out;
	opacity: 0;
	position: absolute;
	background: #1c1f1e;
	width: 100%;
	left: 0;
	border-radius: 0 0 20px 20px;

	&.visible {
		opacity: 1;
		padding: 20px 0;
		grid-template-rows: 1fr;

		.contentLink {
			opacity: 1;

			&:hover {
				opacity: 0.8;
			}
		}

		.tools,
		.image,
		.resources,
		.video {
			height: fit-content;
		}
	}

	&>div {
		overflow: hidden;
	}
}

.contentLink {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 120%;
	color: #ffffff;
	text-decoration: none;
	transform-origin: center;
	cursor: pointer;

	&:hover {
		color: #d28aff;
	}
}

.tools {
	display: flex;
	align-items: baseline;
	justify-content: center;
	gap: 24px;
}

.resources {
	padding: 0;
	display: flex;
	align-items: baseline;
	justify-content: center;
	gap: 24px;
	width: 100%;
}

.title {
	margin: 0 0 40px;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 120%;
	color: #6371de;
	white-space: nowrap;
}

.list {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	list-style: none;
	width: 100%;
	width: fit-content;
}

.video {
	padding: 25px 25px 25px 0;
	height: 0px;
}

.image {
	padding: 25px 0 25px 25px;
	height: 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-left: 1px solid #f9f8fe;
	gap: 40px;
	width: fit-content;

	.title {
		margin: -5px 0 0;
		color: #b472dd;
		width: 100%;
	}
}

.listContainer {
	display: flex;
	gap: 40px;
}

.link {
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 120%;
	color: #ffffff;
	text-decoration: none;
	cursor: pointer;
	width: fit-content;
	flex-shrink: 1;

	&:hover {
		background: linear-gradient(128.75deg, #43cbff -10.96%, #9708cc 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		color: transparent;

		&::after {
			color: #276ef1;
			-webkit-text-fill-color: #276ef1;
		}
	}
}

.newBanner {

	.contentLink,
	.link {
		position: relative;
		display: block;

		&::after {
			position: absolute;
			content: "New";
			top: -26px;
			right: -20px;
			width: 36px;
			height: 24px;
			font-weight: 600;
			font-size: 10px;
			line-height: 24px;
			color: #276ef1 !important;
			background: #f4f1fa;
			border-radius: 10px;
			text-align: center;
		}

		&:hover ::after {
			color: #276ef1 !important;
		}
	}
}

.burgerBtn {
	display: none;
}

.accountInfo {
	display: flex;
	gap: clamp(6px, 0.78vw, 15px);
	align-items: center;
}

.credits {
	color: #fff;
	text-align: right;
	font-size: clamp(14px, 0.83dvw, 16px);
	font-weight: 400;
	line-height: normal;
}

.barbie {
	&.header {
		background: rgba(242, 154, 216, 0.5);

		>*,
		img,
		.authLink,
		.title,
		.logo {
			filter: invert(1);
		}

		.logo {
			color: #000;
		}

		.contentLink:hover,
		.link:hover {
			background: #ebebeb;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			color: transparent;
		}

		.subMenu {
			padding: 40px 20px 20px;
			border-radius: 20px;
		}
	}
}

.adaptive {
	display: none;
}

@media screen and (max-width: 1600px) {
	.header {
		padding: 10px 20px;
	}

	.subMenuWrapper .subMenu {
		padding: 0 20px;
	}

	.open .subMenuWrapper .subMenu {
		padding: 30px 20px;
	}
}

@media screen and (max-width: 1024px) {
	.header {
		padding: 12px 20px;
	}

	.menu {
		gap: clamp(25px, 3.42vw, 35px);
	}

	.authLink {
		min-width: 132px;
		height: 40px;
	}

	.logoLink {
		height: clamp(34px, 3.9vw, 39px);
	}

	.logo {
		width: clamp(104px, 12.7vw, 130px);
		height: clamp(34px, 3.9vw, 40px);
	}

	.list {
		gap: 25px;
	}

	.pricingLink {
		.icon {
			display: none;
		}
	}
}

@media screen and (max-width: 850px) {
	.menu {
		display: none;
	}

	.content {
		display: none;
	}

	.logoLink {
		flex: 1 0 auto;
	}

	.burgerBtn {
		margin-left: auto;
		display: initial;
		border: none;
		background-color: transparent;
		padding: 0;
		height: 24px;

		&:hover {
			opacity: 0.8;
		}

		svg {
			&:last-child {
				display: none;
			}
		}
	}

	.accountInfo {
		&:not(.singIn) {
			display: none;
		}

		&.singIn {
			justify-content: flex-end;
		}

		&.adaptive {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			max-width: 100%;
			width: 320px;
			border-radius: 16px;
			background: rgb(3, 3, 3);
			padding: 12px;
			box-sizing: border-box;
			gap: 10px;

			.authLink {
				flex: 1 0 auto;
			}
		}
	}

	.credits {
		text-align: left;
	}

	.menuOpen {
		.burgerBtn {
			svg {
				&:first-child {
					display: none;
				}

				&:last-child {
					display: block;
				}
			}
		}

		.menu {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			height: calc(100vh - 68px);
			position: absolute;
			top: 100%;
			width: 100%;
			background: #1c1f1e;
			padding: 20px;
			box-sizing: border-box;
			overflow: auto;

			ul {
				max-width: 320px;
				margin: 0;
				display: block;

				li:last-child .menuBtn {
					margin-bottom: 0 !important;
				}

				.menuBtn {
					justify-content: space-between;
					background: rgb(3 3 3);
					width: 320px;
					max-width: 100%;
					padding: 10px 12px;
					box-sizing: border-box;
					margin-bottom: 10px;
					border-radius: 16px;
				}
			}
		}
	}

	.subMenuWrapper {
		position: relative;
		top: -20px;
		border-radius: 0 0 16px 16px;

		.subMenu {
			padding: 0 12px;
			background: rgb(3 3 3);
		}
	}

	.subMenu {
		display: block;

		ul:first-child {
			padding-right: 0;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border: none;
			border-bottom: 1px solid #717187;
		}
	}

	.open {
		.subMenuWrapper {
			.subMenu {
				padding: 14px 12px;
			}
		}
	}

	.imageWrapper {
		display: none;
	}
}