.module_wrapper {
	position: fixed;
	bottom: 1.4vw;
	right: 3.9vw;
	box-shadow: 4px 4px 24px rgba(99, 113, 222, 0.3);
	border-radius: 24px;
	background: #ffffff;
	padding: clamp(16px, 3.75vw, 18px) clamp(16px, 3.75vw, 18px) clamp(20px, 1.3vw, 25px) clamp(16px, 3.75vw, 18px);
	z-index: 11;
}

.close {
	border: none;
	background-color: transparent;
	background-image: url(/ico/close_mobile.png);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	top: 13px;
	right: 16px;
	width: 14px;
	height: 14px;
	pointer-events: all;
	box-sizing: border-box;
	z-index: 2;
}

.data {
	display: flex;
	gap: 8px;
	padding-right: clamp(22px, 2.03vw, 39px);
	position: relative;
	top: -2.5px;

	svg {
		position: relative;
		top: 2.5px;
		left: -1px;
		max-width: clamp(55px, 3.49vw, 67px);
	}

	p {
		font-weight: 400;
		font-size: clamp(16px, 1.04vw, 20px);
		line-height: 134%;
		color: #1c1f1e;
		margin: 0;
		position: relative;
		max-width: clamp(200px, 13.02vw, 250px);
	}
}

.btn {
	display: block;
	width: fit-content;
	margin: auto;
	border: none;
	background: #6371de;
	border-radius: 30px;
	font-size: clamp(16px, 1.04vw, 20px);
	line-height: 175%;
	font-weight: 600;
	color: #ffffff;
	box-sizing: border-box;
	text-align: center;
	text-decoration: none;

	&:hover {
		opacity: 0.8;
	}

	padding: clamp(10px, 0.625vw, 12px) clamp(20px, 1.82vw, 32px);
}

@media screen and (max-width: 500px) {
	.module_wrapper {
		bottom: 20px;
		right: 50%;
		transform: translateX(50%);
		min-width: 300px;
		box-sizing: border-box;
		padding: 10px 18px;
	}

	.data {
		padding-right: 14px;
		p {
			margin-bottom: 0;
		}

		svg {
			top: -2px;
		}
	}

	.btn {
		display: none;
	}
}
