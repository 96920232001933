.container {
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
}

.link {
	padding: 0 10px;
	border-radius: 100px;
	border: 1px solid rgba(85, 85, 85, 0.33);
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(2px);
	min-height: 52px;
	text-decoration: none;
	max-width: 350px;
	width: 100%;
	margin: auto;

	display: flex;
	align-items: center;
	// justify-content: center;

	color: #555;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	box-sizing: border-box;

	&:hover {
		opacity: 0.8;
	}
}

.image {
	margin-right: auto;
	flex-shrink: 0;
	object-fit: contain;
}

.linkText {
	margin-right: auto;
}

.separator {
	color: #b1b3b4;
	text-align: center;
	margin: 18px 0;
}

.emailBtn {
	padding: 0 10px;
	border-radius: 100px;
	background: #6371de;
	backdrop-filter: blur(2px);
	min-height: 52px;
	border: none;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	box-sizing: border-box;
	max-width: 350px;
	width: 100%;
	margin: auto;
	gap: 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		position: relative;
		top: -2px;
	}

	&:hover {
		opacity: 0.8;
	}
}

@media screen and (max-width: 1024px) {
	.link {
		padding: 0 7px;
		text-align: center;
		height: 45px;
		gap: 10px;
	}
}

@media screen and (max-width: 768px) {
	.link {
		font-size: 14px;
	}

	.emailBtn {
		font-size: 14px;
	}

	.separator {
		margin: 5px 0;
	}
}
