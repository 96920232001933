.overlay {
	position: fixed !important;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(10px);
	width: 100vw;
	height: 100vh;
	z-index: 999;
	padding: 5vh 2vw;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	overflow-x: hidden;
	overflow-y: scroll;
}

.closeBack {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	box-sizing: border-box;
}

.wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: stretch;
	min-width: 295px;
	width: fit-content;
	max-width: 1000px;
	width: clamp(900px, 83.33vw, 1100px);
	height: fit-content;
	border-radius: 12px;
	box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
	z-index: 99 !important;
	max-height: 90vh;
	overflow-y: auto;
	overflow-x: hidden;
	box-sizing: border-box;
}

.interactive {
	position: relative;
	padding: 60px 28px 20px;
	width: 50%;
	background: #fff;
	box-sizing: border-box;
	background: #ffffff;
	overflow: auto;

	&.email {
		padding: 60px 28px 20px;
	}
}

.collage {
	width: 50%;
	height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	box-sizing: border-box;

	&.isMain {
		justify-content: flex-end;
		background: url(/authModal/bg/main.webp), linear-gradient(180deg, #a72856 -16.33%, #c566ac 8.65%, #9280ce 37.47%, #563086 68.37%, #1b1c39 98.92%);
		background-repeat: no-repeat;
		background-size: cover;
	}

	&.black {
		color: #1c1f1e;
	}

	&.white {
		color: #fff;
	}
}

.collageText {
	margin: 16px 20px 20px;

	h3 {
		margin: 0 0 10px;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	p {
		margin: 0;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
}

.list {
	color: #fff;
	font-style: normal;
	line-height: normal;
	padding: 30px;

	p {
		font-size: 16px;
		margin: 16px 0 0;

		b {
			font-size: 20px;
		}
	}
}

.collageImage {
	margin: 0 20px;
	max-width: calc(100% - 40px) !important;
	border-radius: 10px 10px 0px 0px;
	box-sizing: border-box;
	width: 100%;
	flex: 1 0 auto;
	object-position: bottom center;
}

.text {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: flex-start;
}

.title {
	margin: 0;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	color: #1c1f1e;
	text-align: center;
	text-wrap: balance;
}

.subtitle {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%;
	border: none;
	background: transparent;
	color: #6371de;
	text-align: left;
	text-align: center;

	&:hover {
		opacity: 0.8;
	}
}

.backBtn {
	padding: 14px;
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	border: none;
	transform: rotate(180deg);

	&:hover {
		opacity: 0.8;
	}

	.backIcon {
		width: clamp(17px, 1.67vw, 32px);
		height: clamp(17px, 1.67vw, 32px);
	}
}

.closeBtn {
	display: none;
}

.lightCloseBtn {
	padding: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	overflow: visible;
	background: transparent;
	border: none;
	transition: 300ms;
	color: #1c1f1e;

	&:hover {
		opacity: 0.8;
	}
}

.message {
	margin: 38px 0;
	text-align: left;
	color: #000;
	font-size: clamp(16px, 1.04vw, 20px);
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

.messageBtn {
	width: 100%;
	max-width: 280px;
	height: 40px;
	border-radius: 30px;
	border: none;
	margin: 0 auto;
	text-decoration: none;
	padding: 10px 21px;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;

	display: table;
	background: linear-gradient(93.03deg, #4b60ff 20.53%, #b415ff 100.38%);
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;

	&:hover {
		opacity: 0.8;
		text-decoration: none;
	}
}

.messageContainer {
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.title {
		line-height: 120%;
	}

	.interactive {
		padding: 40px 33px 20px;

		&.email {
			padding: 40px 33px 20px;
		}
	}

	.wrapper {
		width: 700px;
		height: fit-content;
		min-height: 500px;
	}

	.collage {
		object-fit: cover;
		max-width: 100%;
		height: auto;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		width: 335px;
		max-height: calc(100vh - 40px);
		min-height: auto;
	}

	.title {
		font-size: 20px;
		text-align: center;
	}

	.subtitle {
		text-align: center;
	}

	.interactive {
		width: 100%;
	}

	.collage {
		display: none;
	}

	.closeBtn {
		display: initial;
		padding: 20px;
		position: absolute;
		top: 0;
		right: 0;

		background: transparent;
		border: none;

		&:hover {
			opacity: 0.8;
		}

		.closeIcon {
			width: 16px;
			height: 16px;

			rect {
				fill: none;
			}
		}
	}

	.message {
		text-align: center;
		font-size: 20px;
	}

	.lightCloseBtn {
		display: none;
	}

	.interactive {
		padding: 30px 16px 20px;

		&.email {
			padding: 30px 16px 20px;
		}
	}
}

@media screen and (max-width: 350px) {
	.wrapper {
		width: calc(100% - 20px);
		min-width: 250px;
	}
}
