.survey_wrapper {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999 !important;
	background: rgba(0, 0, 0, 0.1);

	&.servey {
		justify-content: center;
		align-items: center;
	}

	&.question {
		justify-content: flex-end;
		align-items: flex-end;
		box-sizing: border-box;
		padding: 0 20px 20px 0;
		* {
			text-align: left;
		}
	}
}

.disable * {
	cursor: wait;
	pointer-events: none;
}

.survey_item {
	overflow: hidden;
	border-radius: 24px;
	position: relative;
	max-width: 540px;
	width: calc(100% - 40px);
	box-sizing: border-box;

	&:not(.bg-top) {
		background: #fff;
	}

	&.bg-top {
		background: linear-gradient(180deg, #b472dd 30px, #fff 30px);
	}
}

.close {
	position: absolute;
	right: 7px;
	top: 9px;
	background: none;
	border: none;
	padding: 5px;
	z-index: 2;
	color: #fff;
}

.survey_title {
	background: #b472dd;
	padding: 0 23px;
	height: clamp(70px, 6.72vw, 129px);
	display: flex;
	justify-content: center;
	align-items: center;

	/* Congrats! */

	font-style: normal;
	font-weight: 700;
	font-size: clamp(24px, 1.875vw, 36px);
	line-height: 100%;

	color: #ffffff;
	text-shadow: 4px 7px 8px rgba(107, 64, 133, 0.8);

	svg {
		max-width: clamp(55px, 4.01vw, 77px);
		max-height: clamp(55px, 4.01vw, 77px);
	}
}

.survey_content {
	padding: clamp(30px, 2.29vw, 44px) clamp(16px, 1.2vw, 23px) clamp(16px, 1.04vw, 20px);
}

.title {
	color: #1c1f1e;
	font-size: clamp(20px, 61.25vw, 24px);
	font-weight: 700;
	text-align: center;
	margin: 0 0 8px;
}

.question {

	.close {
		color: #000;
	}

	h2.title 
	 {
		font-size: clamp(34px, 2.19vw, 42px);
		line-height: 100%;

		+ .text {
			line-height: 120%;
			font-weight: 400;
		}
	}

	.text {
		font-size: clamp(16px, 1.04vw, 20px);
	}

	.survey_item {
		max-width: 398px;
	}

	.survey_content {
		padding-top: clamp(16px, 1.04vw, 20px);
	}
}

.text {
	font-size: clamp(16px, 1.04vw, 19px);
	line-height: 140%;
	text-align: center;
	color: #000;
	margin: 0;
	padding: 0;
	white-space: break-spaces;
	font-weight: 600;
	span {
		color: #c2c2c2;
	}
}

.rating {
	margin: clamp(10px, 2.08vw, 40px) 0 clamp(20px, 2.6vw, 50px);
	display: flex;
	justify-content: center;
	gap: clamp(8px, 0.625vw, 12px);
}

.rating_item {
	background: #f9f8fe;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	border: none;
	width: clamp(40px, 4.69vw, 90px);
	height: clamp(40px, 4.69vw, 90px);
	padding-top: clamp(0px, 0.98vw, 10px);
	box-sizing: border-box;
	transition: all 0.4s ease-in-out;
	margin-bottom: 10px;
	padding: 0;

	&:hover {
		transform: scale(1.05);
		box-shadow: 1px 1px 8px rgba(107, 64, 133, 0.8);
	}

	> * {
		max-width: 100%;
		object-fit: contain;
	}
}

.textarea, .input {
	width: 100%;
	padding: 14.5px 16px;
	margin: 16px 0 0;
	border-radius: 16px;
	border: 1.5px solid #1c1f1e;
	background: #fff;
	box-sizing: border-box;
	resize: none;
	font-size: clamp(16px, 1.04vw, 20px);
	line-height: 140%;

	&::placeholder {
		color: #c2c2c2;
	}
}

.input {
	height: clamp(45px, 3.13vw, 60px);
}

.textarea {
	height: clamp(100px, 6.25vw, 120px);
}

.btn {
	background: #6371de;
	border-radius: 16px;
	margin: 0 auto clamp(10px, 1.56vw, 30px);
	display: flex;
	width: clamp(250px, 16.67vw, 320px);
	height: clamp(45px, 3.13vw, 60px);
	border-radius: 50px;
	padding: 10px;
	justify-content: center;
	align-items: center;
	border: none;
	color: #fff;
	font-weight: 500;
	font-size: clamp(16px, 1.04vw, 20px);
	line-height: 100%;

	&:hover {
		opacity: 0.8;
	}

	&:disabled {
		filter: grayscale(0.6);
		cursor: not-allowed;
	}
}

.promo_code {
	background: linear-gradient(128.75deg, #43cbff -10.96%, #9708cc 100%);
	border-radius: 16px;
	padding: 2px;
	box-sizing: border-box;
	margin: clamp(10px, 1.56vw, 30px);
	height: clamp(70px, 5.05vw, 97px);
	display: flex;
	gap: 2px;
}

.promo_code_text {
	flex: 1 0 auto;
	padding: 0 0 0 clamp(24px, 1.875vw, 36px);
	font-size: clamp(20px, 1.82vw, 32px);
	font-weight: 600;
	display: flex;
	align-items: center;
	color: #1c1f1e;
	background: #fff;
	border-radius: 14px 0 0 14px;
}

.promo_code_btn {
	background: #fff;
	border-radius: 0 14px 14px 0;
	height: 100%;
	border: none;
	width: 92px;

	svg {
		max-width: clamp(40px, 2.6vw, 50px);
		max-height: clamp(40px, 2.6vw, 50px);
	}
}