.container {
  position: absolute;
  width: 680px;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  border-radius: 16px;
  background: radial-gradient(130.39% 38.18% at 75.42% 20.79%, #FCD2FF 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(74.8% 37.15% at 29.71% 83.42%, #C5D3FF 0%, #E1D0FD 100%);
  overflow: hidden;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scene {
  width: 100%;
  height: auto;
  aspect-ratio: 680 / 446;
}

.content {
  width: 100%;
  padding: 14px 20px;
  color: #1C1F1E;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0 0 9px;
}

.subtitle {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
}

.discoverButton {
  font-size: 16px;
  border-radius: 100px;
  background: #1C1F1E;
  border: 1.5px solid #1C1F1E;
  color: white;
  cursor: pointer;
  padding: 10px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-height: 44px;
  text-decoration: none;
  white-space: nowrap;


  &:hover {
    background: transparent;
    color: #1C1F1E;

  }

  * {
    pointer-events: none;
  }
}

.modalWrapperPortal {
  position: fixed !important;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  z-index: 999;
  padding: 2vh 2vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.modalCloseBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.close {
  border: none;
  background-color: transparent;
  background-image: url(/ico/close_mobile.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 14px;
  height: 14px;
  pointer-events: all;
  box-sizing: border-box;
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .container {
    width: 400px;
    overflow: auto;
  }

  .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 14px;
  }

  .content {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
  }

  .text {
    width: fit-content;
  }

  .discoverButton {
    width: 100%;
    padding: 6px;
    height: 38px;
  }
}

@media screen and (max-width: 400px) {
  .scene {
    max-height: 200px;
    object-fit: cover;
  }
}