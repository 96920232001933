.footer {
	z-index: 6;
	margin: 0;
	padding: clamp(40px, 4.7vw, 80px) calc(50% - 1540px / 2) 80px;
	position: relative;
	display: flex;
	justify-content: center;
	background: #1c1f1e;
	box-sizing: border-box;
	height: fit-content;
	width: 100%;
	gap: 80px;
	overflow: hidden;
}

.logo {
	display: block;
	width: clamp(120px, 10.2vw, 196px);
	height: clamp(35px, 3.125vw, 60px);
	color: #fff;
}

.left {
	display: flex;
	flex-direction: column;
	gap: 45px;
}

.language {
	position: relative;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: fit-content;

	&:hover {
		.langList {
			opacity: 1;
			visibility: visible;
		}

		.arrowIcon {
			transform: rotate(180deg);
		}
	}
}

.middle {
	margin: 0;
	padding: 5px 30px;
	display: flex;
	justify-content: center;
	gap: clamp(20px, 3.125vw, 58px);
}

.listWrapper {
	display: flex;
	flex-direction: column;
}

.list {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
	list-style: none;
	min-width: 116px;
}

.listTitle {
	margin-bottom: 12px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 120%;
	color: #ffffff;
	border: none;
	background: none;
	padding: 0;
	text-align: left;

	svg {
		display: none;
	}
}

.item {
	margin-bottom: -1.5px;
}

.link {
	font-style: normal;
	font-weight: 300;
	font-size: clamp(14px, 0.83vw, 16px);
	line-height: 120%;
	color: #ffffff;
	text-decoration: none;
	text-wrap: nowrap;
}

.right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.title {
	margin: 0 0 12px;
	font-weight: 700;
	font-size: clamp(14px, 0.83vw, 16px);
	line-height: 120%;
	color: #ffffff;
	text-align: right;
	max-width: 220px;
}

.icons {
	display: flex;
	gap: clamp(16px, 1.04vw, 20px);
	width: 100%;
	justify-content: flex-end;

	a:hover {
		filter: invert(1);
	}
}

.copyright {
	margin-top: auto;
	font-style: normal;
	font-weight: 300;
	font-size: clamp(14px, 0.83vw, 16px);
	line-height: 120%;
	text-align: left;
	color: #717187;
}

.btn {
	position: absolute;
	border: none;
	bottom: 12px;
	left: 50%;
	transform: translate(-50%);
	margin-top: 40px;
	border-radius: 100px;
	background: #6371de;
	display: flex;
	padding: 10px 32px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	color: #fff;
	width: fit-content;
	text-decoration: none;

	svg {
		position: relative;
		left: 0;
		transition: all 0.5s;
	}

	&:hover {
		svg {
			left: 5px;
		}
	}
}

@media screen and (max-width: 1550px) {
	.footer {
		padding: clamp(40px, 4.7vw, 80px) 20px 80px;
		gap: 20px;
		overflow: hidden;
	}
}

@media screen and (max-width: 1200px) {
	.footer {
		display: block;
	}

	.left {
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 40px;
		gap: 30px;
		flex-wrap: wrap;
	}

	.copyright {
		position: absolute;
		bottom: 80px;
		right: 20px;
	}

	.middle {
		width: 100%;
		padding: 0;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

@media screen and (min-width: 769px) {
	.listTitle {
		cursor: default;
		pointer-events: none;
		user-select: none;
	}
}

@media screen and (max-width: 768px) {
	.footer {
		padding-bottom: 90px;
	}

	.middle {
		flex-wrap: wrap;
	}

	.listWrapper {
		min-width: 47%;
	}

	.btn {
		bottom: 40px;
		max-width: 320px;
		width: calc(100% - 40px);
		padding: 10px 10px;
		font-size: 14px;
	}

	.copyright {
		right: 50%;
		transform: translateX(50%);
		bottom: 30px;
		width: calc(100% - 40px);
		text-align: center;
	}
}

@media screen and (max-width: 500px) {
	.middle {
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.listWrapper {
		width: 100%;
		border-radius: 16px;
		background: rgba(0, 0, 0, 0.5);
		padding: 12px;
		box-sizing: border-box;
	}

	.grid {
		padding: 0;
		display: grid;
		grid-template-rows: 0fr;
		transition: grid-template-rows 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.3s ease-in-out;
		opacity: 0;
	}

	.list {
		gap: 16px;
		overflow: hidden;
	}

	.listTitle {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;

		svg {
			display: block;
		}
	}

	.open+.grid {
		padding: 12px 0 0;
		opacity: 1;
		grid-template-rows: 1fr;
	}

	.item {
		margin: 0;
	}
}

// .btnWrapper {
// 	display: flex;
// 	justify-content: center;
// 	width: 100%;
// 	height: 50px;
// 	animation-name: rotateAnimation;
// 	animation-duration: 1ms; /* Firefox requires this to apply the animation */
// 	animation-direction: alternate;
// 	animation-timeline: scroll(block nearest);
// 	box-sizing: border-box;
// 	z-index: 100000;
// 	position: static;
// }

// @keyframes rotateAnimation {
// 	0% {
// 		opacity: 0;
// 		position: fixed;
// 		background: linear-gradient(360deg, rgba(255, 255, 255, 0.431) 1.25%, rgba(255, 255, 255, 0) 67.5%);
// 		bottom: 0;
// 		left: 0;
// 	}

// 	20% {
// 		opacity: 1;
// 		position: fixed;
// 	}

// 	79% {
// 		position: fixed;
// 		background: linear-gradient(360deg, rgba(255, 255, 255, 0.431) 1.25%, rgba(255, 255, 255, 0) 67.5%);
// 		bottom: 0;
// 		left: 0;
// 	}

// 	95% {
// 		position: static;
// 		background: transparent;
// 	}

// 	100% {
// 		position: static;
// 		opacity: 1;
// 	}
// }