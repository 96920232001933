.wrapper {
	height: 140px;
	z-index: 20;
	width: 100vw;
	max-width: 100%;
	background: transparent;
	position: relative;
	overflow: hidden;
	margin-bottom: -40px;
}

.container {
	height: 100px;
	width: 100%;
	background: radial-gradient(130.39% 38.18% at 75.42% 20.79%, #FCD2FF 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(74.8% 37.15% at 29.71% 83.42%, #C5D3FF 0%, #E1D0FD 100%);
	box-sizing: border-box;
	display: flex;
	justify-content: left;
	align-items: center;
	color: #1C1F1E;
	padding: 15px calc(50% - 770px);
}

.text {
	width: 100%;
}

.title {
	font-size: clamp(18px, 1.88vw, 28px);
	font-weight: 600;
	line-height: normal;
	margin: 0;
	white-space: nowrap;
}

.description {
	font-size: 17px;
	line-height: 140%;
	margin: 0;
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #1C1F1E;
	border-radius: 200px;
	padding: 5px 22px;
	color: #FFF;
	text-align: center;
	font-size: 17px;
	font-weight: 500;
	line-height: 160%;
	text-decoration: none;
	transform-origin: center;
	white-space: nowrap;
	box-sizing: border-box;
	border: 1px solid transparent;
	transition: all .5s ease;

	&:hover {
		color: #1C1F1E;
		border: 1px solid #1C1F1E;
		background-color: transparent;
	}

	svg {
		max-width: clamp(14px, 1.09vw, 21px);
	}
}

.closeBtn {
	display: initial;
	position: absolute;
	right: 11px;
	top: 11px;
	border: none;
	background-color: transparent;
	color: #fff;

	&:hover {
		opacity: 0.8;
	}
}

@media screen and (max-width: 1500px) {
	.container {
		padding: 15px 40px;
	}
}

@media screen and (max-width: 1200px) {
	.container {
		box-shadow: 0px 4px 5.774px 0px rgba(104, 33, 10, 0.25);
	}

	.title {
		line-height: 120%;
		white-space: pre-wrap;
	}

	.description {
		font-size: clamp(12px, 1.57vw, 17px);
		text-wrap: balance;
	}

	.button {
		border-radius: 100px;
		height: 40px;
		justify-content: center;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
		min-width: clamp(145px, 17.13vw, 185px);
		width: fit-content;
		box-shadow: 0px 4px 5.774px 0px rgba(104, 33, 10, 0.25);
		margin-left: auto;
		font-size: clamp(14px, 2.22vw, 17px);
	}
}

@media screen and (max-width: 1000px) {
	.description {
		margin-right: 30px;
	}
}

@media screen and (max-width: 800px) {
	.title {
		font-size: 14px;
	}

	.description {
		font-size: 11px;
	}
}

@media screen and (max-width: 700px) {
	.wrapper {
		max-width: 100vw;
		height: fit-content;
		padding-bottom: 40px;
	}

	.container {
		height: fit-content;
		width: 100vw;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		padding: 10px 20px 8px;
		gap: 5px;
	}

	.text {
		width: 100%;
	}

	.title {
		font-size: 12px;
	}

	.title,
	.description {
		width: 100%;
		margin: auto;
		text-wrap: initial;
	}

	.description {
		margin: 2px auto 0;
	}

	.image {
		min-width: 0;
		width: 59px;
		height: 59px;
		top: 0;
		margin: 0 0 -50px !important;
	}

	.button {
		font-size: 14px;
		position: static;
		height: 100%;
		min-width: fit-content;
		padding: 6px 10px;
		box-shadow: none;
	}

	.closeBtn {
		top: 5px;
		right: 2px;

		svg {
			width: 20px;
			height: 20px;
		}
	}
}