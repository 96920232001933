.language {
	position: relative;
}

.langActive {
	border-radius: 100px;
	background: #1c1f1e;
	border: none;
	color: #fff;
	font-size: 16px;
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 2px 10px;
	height: 36px;
	box-sizing: border-box;
	transition: all 0.3s;

	* {
		pointer-events: none;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.5);
	}
}

.listWrapper {
	position: absolute;
	top: 100%;
	display: grid;
	opacity: 0;
	grid-template-rows: 0fr;
	padding: 0px 12px;
	transition: grid-template-rows 0.5s ease-in-out, opacity 0.8s ease-in-out, padding 0.2s ease-in-out;
	overflow: hidden;
	border-radius: 16px;
	background: #1c1f1e;
	width: 80px;

	.langList {
		overflow: hidden;
	}
}

.language.open {
	.langActive {
		.arrowIcon {
			transform: rotate(180deg);
		}

		&:hover {
			background: transparent;
		}
	}

	.listWrapper {
		padding: 12px;
		transition: grid-template-rows 0.5s ease-in-out, opacity 0.5s ease-in-out, padding 0.2s ease-in-out;
		opacity: 1;
		grid-template-rows: 1fr;
	}
}

.langList {
	padding: 0;
	list-style: none;
	color: #fff;
	text-align: center;
	box-sizing: border-box;
}

.langItem {
	cursor: pointer;
	&:not(:last-child) {
		margin-bottom: 6px;
	}

	&:hover {
		color: #b472dd;
	}
}

@media screen and (min-width: 851px) {
	.language.adaptive {
		display: none;
	}
}

@media screen and (max-width: 850px) {
	.language.desktop {
		display: none;
	}

	.language {
		background: rgb(3, 3, 3);
		width: 320px;
		box-sizing: border-box;
		margin-bottom: 10px;
		border-radius: 16px;
	}

	.langActive {
		background: none;
		width: 320px;
		height: 39.3px;
		max-width: 100%;
		justify-content: space-between;

		.arrowIcon {
			width: 13px;
			height: 8px;
		}

		span {
			flex: 1 0 auto;
			text-align: left;
		}
	}

	.listWrapper {
		position: static;
		background: none;
		width: 100%;
		box-sizing: border-box;
	}

	.langList {
		text-align: left;
	}

	.langItem {
		line-height: 32px;
		max-width: 100%;
		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}
}

.barbie {
	filter: invert(1);
}
