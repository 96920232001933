.auth_form {
	position: relative;
	margin: 0 auto 50px;
	padding: 42px 0 30px;
	width: 362px;
	height: auto;
	background: #ffffff;
	border-radius: 20px;
	box-sizing: border-box;
	z-index: 1;
	background: rgba(217, 218, 219, 0.01);
	box-shadow: 4px 4px 24px rgba(99, 113, 222, 0.3);
}

.form {
	margin-top: 32px;
	height: 100%;
	padding-top: 5px;
	color: #38373a;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 18px;
	width: 100%;

	.item {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		max-width: 350px;

		input {
			padding-left: 10px;
			margin: 5px auto 0;
			width: 100%;
			height: 41px;
			border-radius: 20px;
			border: 1.5px solid #d4cfdd;
			box-sizing: border-box;
		}

		label {
			padding-left: 15px;
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;
			color: #58585a;
		}
	}

	.forgot-password {
		margin: 18px auto 0;

		a {
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;
			color: #b2a9c1;
			text-decoration: none;
		}
	}

	.btn-text {
		margin: 6px auto 0;
		display: block;
		width: fit-content;
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
		color: #b1b3b4;
		text-align: center;
	}

	.btn {
		width: 100%;
		max-width: 280px;
		height: 40px;
		border-radius: 30px;
		border: none;
		margin: 0 auto;
		text-decoration: none;
		padding: 10px 21px;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
			text-decoration: none;
		}
	}

	.btn {
		padding: 0 10px;
		border-radius: 100px;
		background: #6371de;
		backdrop-filter: blur(2px);
		min-height: 52px;
		border: none;
		color: #fff;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		box-sizing: border-box;
		max-width: 350px;
		width: 100%;
		margin: 20px auto 0;
		gap: 10px;

		display: flex;
		justify-content: center;
		align-items: center;

		&:hover {
			opacity: 0.8;
		}
	}

	.social-auth {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 10px;
		margin: 4px 45px;
	}

	.social-auth-btn {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		font-weight: 500;
		font-size: clamp(12px, 0.73vw, 14px);
		line-height: 150%;
		color: #58585a;
		position: relative;
		outline: none;

		&:hover::after {
			content: attr(data-name);
			position: absolute;
			bottom: calc(100% + 10px);
			display: block;
			box-sizing: border-box;
			padding: 2px 5px;
			border-radius: 5px;
			background: hsla(0deg, 0%, 100%, 0.8);
			font-style: normal;
			font-weight: 400;
			font-size: clamp(12px, 0.73vw, 14px);
			line-height: 120%;
			text-align: center;
			color: #58585a;
			white-space: nowrap;
			box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
		}
	}

	.form_text {
		margin: 0 auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
		color: #58585a;

		+ .form_item {
			margin-top: 47px;
		}

		a {
			text-decoration: none;
			font-weight: 700;
			font-size: 12px;
			line-height: 150%;
			color: #6371de;
		}
	}

	.form_info {
		margin-top: 30px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
	}
}

@media screen and (max-width: 480px) {
	.form {
		margin-top: 12px;
		gap: 12px;

		.btn {
			margin-top: 10px;
			min-height: 35px;
		}

		.item {
			input {
				height: 35px;
				font-size: 14px;
				padding: 2px 15px;
			}
		}
	}
}

.title {
	margin: 0 0 28px;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	line-height: 150%;
	position: relative;
	color: #1c1f1e;
}

.closeBtn {
	position: absolute;
	padding: 5px;
	top: 10px;
	right: 10px;
	cursor: pointer;

	rect {
		fill: #fff;
	}
}

.form-title {
	margin: 0;
	padding-bottom: 55px;
	text-align: center;
	font-weight: 500;
	font-size: 18px;
	line-height: 111%;
	color: #000000;
}

.error,
.email-error,
.password-error,
.request_error {
	color: #e85955;
}

.request_error {
	width: 100%;
	font-weight: 600;
	text-align: center;
	position: absolute;
	bottom: 100%;
	padding: 0 20px;
	box-sizing: border-box;
	font-size: 14px;
}

.window-letter {
	margin: 150px auto;
	padding: 30px 30px;
	width: 512px;
	height: 512px;
	background: #ffffff;
	border-radius: 20px;
	box-sizing: border-box;
	text-align: center;
}

.agreement-checkbox {
	position: relative;
	top: -5px;
}

.agreement-error {
	color: #e85955;
}

.agreement-text {
	font-size: 10px;
	line-height: 150%;
	color: #38373a;
}

.email-error,
.password-error {
	position: absolute;
	top: calc(100% + 5px);
	left: 15px;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 12px;
}

.agreement-error {
	margin: 5px 0 0 15px;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 12px;
}

.info {
	&-title {
		margin: 10px auto 0;
		width: 90%;
		font-weight: 600;
		font-size: 20px;
		line-height: 40px;
		color: #000000;
	}

	&-content {
		margin: 30px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
		width: 95%;
	}

	&-text {
		font-weight: 400;
		font-size: 16px;
		line-height: 30px;
		color: #000000;
	}

	&-btn {
		margin-top: 10px;
		width: 200px;
		height: 40px;
		border: none;
		background: linear-gradient(93.03deg, #4b60ff 20.53%, #b415ff 100.38%);
		border-radius: 20px;

		a {
			text-decoration: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 21px;
			color: #ffffff;
		}
	}
}

.agreement {
	position: relative;
	margin: 0 auto;
	display: flex;
	gap: 10px;
	width: 100%;

	&-checkbox {
		max-width: 15px !important;
		height: 15px !important;
		border: 1px solid #b1b3b4;
	}

	&-text {
		font-weight: 400;
		color: #38373a;
		font-size: 10px;
	}

	a {
		text-decoration: none;
		color: rgba(99, 113, 222, 1);

		&:hover {
			opacity: 0.8;
		}
	}
}

@media screen and (max-width: 768px) {
	.window-letter {
		padding: 55px 10px;
		width: 80%;

		.info-title {
			margin-top: 20px;
			font-size: 22px;
		}

		.info-text {
			font-size: 18px;
		}
	}
}
