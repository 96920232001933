.questionBtn {
	position: fixed;
	bottom: 15px;
	right: 15px;
	min-width: 40px;
	width: fit-content;
	height: 40px;
	border-radius: 20px;
	background-color: #b472dd;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	border: none;
	box-sizing: border-box;
	overflow: hidden;
	z-index: 900;
	padding: 0;

	color: #fff;

	span {
		display: none;
	}

	&:hover {
		padding: 12px;

		span {
			display: block;
		}
	}

	* {
		pointer-events: none;
	}

	svg {
		position: relative;
		left: 2.5px;
		top: 1px;
	}
}

@media screen and (max-width: 500px) {
	.questionBtn {
		bottom: 2.5px;
		right: 2.5px;
	}
}
